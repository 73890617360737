import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAuthToken } from '../../helpers/authentication';

export default class EngagementModal extends Component {

  constructor(props) {
    super(props);

    this.state = {
      editModal: false,
      Percentage: 0,
      Comment: '',
    }

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  showModal = () => {
    this.setState({
      editModal: true
    });
  }

  saveEngagement = async () => {
    if (this.props.SaveFunction)
      this.props.SaveFunction(this.state.Percentage, this.state.Comment, this.props.Data.Route);
    else {
      var bearer = 'Bearer ' + getAuthToken();
      var data = { Id: 0, RiskId: this.props.Data.Id, Percentage: this.state.Percentage, Note: this.state.Comment }
      console.log(data);
      try {
        const response = await fetch(this.props.Data.Route, {
          method: 'POST',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          await response.json();
          if (this.props.EngagementSaved)
            this.props.EngagementSaved();
          ToastUtility.show({
            title: 'Risk Status', content: 'The risk status was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
          });
        }
        else {
          console.error(response.status + ": " + response.statusText);
        }

      } catch (e) {
        console.error(e);

        ToastUtility.show({
          title: 'Risk Status', content: 'There was an error saving the risk status!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
    }
    this.toggle();
  }

  render() {
    return (
      <Modal isOpen={this.state.editModal} toggle={this.toggle} scrollable size="md" backdrop="static">
        <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>{this.props.Title}</ModalHeader>
        <ModalBody>
          <div className='row'>
            <div className='mb-3'>
              <NumericTextBoxComponent id='tbRiskAnalysis' name='tbRiskAnalysis' min={0} max={100} format="N0" placeholder='Percentage' floatLabelType='Always' showClearButton={true} value={this.props.Data.PreviousPercentage} onChange={e => this.setState({ Percentage: e.target.value })} /> <div id='tbRiskAnalysisError' className='error-message' />
            </div>
            <div className='mb-3'>
              <TextBoxComponent multiline id='tbRiskAnalysisComment' name='tbRiskAnalysisComment' placeholder='Comment' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={""} onChange={e => this.setState({ Comment: e.target.value })} /> <div id='tbRiskAnalysisCommentError' className='error-message' />
            </div>

            {(this.props.Data.Id > 0) ? <>
              <h5 className="border-bottom">Previous Engagement</h5>
              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbPreviousPercentage' name='tbPreviousPercentage' placeholder='Percentage' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.props.Data.PreviousPercentage} />
              </div>
              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbModifiedBy' name='tbModifiedBy' placeholder='Modified By' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.props.Data.UserName} />
              </div>
              <div className='mb-3 col-md-4'>
                <TextBoxComponent id='tbPreviousDate' name='tbPreviousDate' placeholder='Date Modified' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={new Date(this.props.Data.PreviousDate).toProperDate()} />
              </div>
              <div className='mb-3 col-md-12'>
                <TextBoxComponent multiline id='tbPreviousComment' name='tbPreviousComment' placeholder='Comment' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={this.props.Data.PreviousComment} />
              </div>
            </> : ""}

          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="success" size="sm" onClick={() => this.saveEngagement()}>Save <i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>
    )
  }
}

