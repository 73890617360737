import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { createRef, useEffect, useState } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import { ConfirmDialogStyles, GetProgressStyle } from '../../../helpers/global';
import EngagementModal from '../EngagementModal';
import { LoadAPIData, DeleteAPIData } from '../../../helpers/DataHelper';


const BusinessObjectives = ({ RiskId, ObjectiveData, EventHandler }) => {

  const [businessObjectiveId, setBusinessObjectiveId] = useState(0);
  const [objectives, setObjectives] = useState();
  const [allObjectives, setAllObjectives] = useState();
  const [riskStatus, setRiskStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);

  const toggle = () => setEditModal(!editModal);


  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const statusRef = createRef();

  const saveItem = async (event) => {
    event.stopPropagation();
    const userData = getUserDetails();

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: 0, RiskId: RiskId, BusinessObjectiveId: businessObjectiveId }
    //console.log(data);
    try {
      const response = await fetch('api/riskbusinessobjectives/' + userData.UserRole, {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        if (EventHandler)
          EventHandler();
        setBusinessObjectiveId(0);
        loadData();

        ToastUtility.show({
          title: 'Risk Business Objectives', content: 'The business objectives was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }
      setEditModal(false);
    } catch (e) {
      console.error(e);
      ToastUtility.show({
        title: 'Risk Business Objectives', content: 'There was an error saving the business objectives!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
      setEditModal(false);
    }
  }

  const loadData = async () => {
    const User = getUserDetails();

    const data = await LoadAPIData(`api/businessobjectives/listallbycompany/${User.CompanyId}/${User.UserRole}`);
    //console.log(data);
    setAllObjectives(data);
    setObjectives(data.filter(item => {
      return !ObjectiveData?.some(ob => ob.BusinessObjectiveId === item.Id);
    }));
    loadRiskStatus();

  }

  const loadRiskStatus = async () => {
    const User = getUserDetails();
    setRiskStatus(await LoadAPIData(`api/riskbusinessobjectivestatus/getbyriskid/${RiskId}/${User.UserRole}`));
    setLoading(false);
  }

  const deleteItem = async (dataId) => {
    if (await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles)) {
      const userData = getUserDetails();
      const result = await DeleteAPIData('/api/riskbusinessobjectives/' + userData.UserRole + "/" + dataId)
      if (result) {
        loadData();
        if (EventHandler)
          EventHandler();
        ToastUtility.show({
          title: 'Risk Business Objectives', content: 'The risk business objective was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        ToastUtility.show({
          title: 'Risk Business Objectives', content: 'There was an error deleting the risk business objective!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }

    }
  }

  const descriptionTemplate = (data) => {
    return (
      <>
        {data.Title}
        <div className="dropdown-detail">{data.Description}</div>
      </>
    );
  }

  useEffect(() => {
    loadData();
  }, []);


  if (!loading)
    return (
      <>

        <div className='text-center' style={GetProgressStyle(riskStatus?.Percentage)}><small>{riskStatus?.Percentage > 0 ? `${riskStatus?.Percentage}% - ${new Date(riskStatus?.DateModified).toProperDate()}` : "Not Yet Started"}</small></div>
        <div className='card h-100 flat-top'>
          <div className='card-header'>
            <table className="w-100">
              <tbody>
                <tr>
                  <td>Business Objectives</td>
                  <td className="text-end"><i className="fa-regular fa-square-plus" onClick={toggle} title='Add Business Objective'></i></td>
                </tr>
              </tbody>
            </table>

            <small>(Click the <strong>"+"</strong> icon to add a Business Objective)</small>
          </div>
          <div className='card-body'>

            <div className='d-flex flex-column justify-content-between'>
              {ObjectiveData && ObjectiveData.map((item, index) => {
                  const tooltipId = `tooltip-objective-${index}`;

                  return (
                    <div key={index} className='d-flex justify-content-between align-items-center'>
                      <span id={tooltipId}>
                        {item.BusinessObjectiveName}
                      </span>
                      <Tooltip placement="top" isOpen={tooltipOpen[tooltipId] || false} toggle={() => toggleTooltip(tooltipId)} target={tooltipId}>
                        {
                          allObjectives?.find((obj) => obj.Id === item.BusinessObjectiveId)?.Description
                        }
                      </Tooltip>
                      <i onClick={() => deleteItem(item.Id)} className="far fa-trash-can text-danger" title='Remove Business Objective'></i>
                    </div>
                  );
                })}
            </div>
            
          </div>
          <div className="card-footer text-center">
            {ObjectiveData?.length > 0 ? <button onClick={() => statusRef?.current.showModal()} className='btn btn-info btn-sm'><i className="far fa-edit me-2"></i>Update Status</button> : ""}
          </div>
        </div>



        <Modal isOpen={editModal} toggle={toggle} size="md" backdrop="static">
          <ModalHeader toggle={toggle}>Business Objectives</ModalHeader>
          <ModalBody>
            <div className='mb-3'>
              <DropDownListComponent id='ddBusinessObjectiveId' name='ddBusinessObjectiveId' placeholder='Add a Business Objective' itemTemplate={descriptionTemplate} dataSource={objectives?.length > 0 ? [{ Title: "-SELECT-", Id: 0 }, ...objectives] : objectives} fields={{ text: 'Title', value: 'Id' }} floatLabelType='Always' value={businessObjectiveId} change={e => setBusinessObjectiveId(e.value)} /><div id='tbBusinessObjectiveIdError' className='error-message' />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <EngagementModal key="mdlStatus" ref={statusRef} Title="Business Objective Status" EngagementSaved={loadRiskStatus} Data={{ Id: RiskId, PreviousPercentage: riskStatus.Percentage, UserName: riskStatus.UserName, PreviousDate: riskStatus.DateModified, PreviousComment: riskStatus.Note, Route: 'api/riskbusinessobjectivestatus/' + getUserDetails().UserRole }} />
      </>
    );
};

export default BusinessObjectives;