import { NumericTextBoxComponent, TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from "@syncfusion/ej2-react-notifications";
import { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getAuthToken } from "../../../helpers/authentication";
//import TooltipComponent from '../../Common/ToolTip';

const RiskStatusTitle = ({ Title, RiskId, StatusData, HintText, StatusPath, RiskType, UpdateHandler }) => {
  const [note, setNote] = useState('');
  const [percentage, setPercentage] = useState(StatusData?.Percentage);
  const [editModal, setEditModal] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const toggle = () => setEditModal(!editModal);
  const toggleHelp = () => setHelpModal(!helpModal);

  const saveItem = async (event) => {
    event.stopPropagation();
    var bearer = 'Bearer ' + getAuthToken();
    var data = { RiskId: RiskId, Percentage: percentage, Note: note, Type: RiskType }
    //console.log(data);
    //console.log(StatusPath);
    if (!StatusPath) {
      StatusPath = "/api/riskevaluation/updatestatus";
      //StatusData?.Percentage = percentage;
    }
    try {
      const response = await fetch(StatusPath, {
        method:'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        setNote("");
        if (UpdateHandler)
          UpdateHandler();  
        ToastUtility.show({
          title: Title + ' Status', content: 'The status was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: Title + ' Status', content: 'There was an error saving the status!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }

    toggle();
  }


  const renderHelp = () => {
    if (HintText !== '') {
      return (
        <td className="text-end" style={{ width: "30px" }}>
          <i className="fa-solid fa-circle-question" style={{ cursor: "pointer" }} onClick={() => toggleHelp()}></i>
        </td>
      )
    }
  }

  const renderPercentage = (Percentage) => {
    if (Percentage === 0)
      return (<em>Not Yet Started</em>);
    else
      return (<em>{Percentage}%  {Percentage < 100 ? "Adequate" : "Completed"}</em>)
  }

  if (StatusData) {
    
    return (
      <>
        <div className='risk-title-section mt-3'>
          <table className="w-100">
            <tbody>
              <tr>
                <td>
                  {/*<h3 className='text-white m-0 '><b>{Title}:</b> <em>{StatusData?.Percentage}%  {StatusData?.Percentage < 100 ? "Adequate" : "Completed"}</em></h3>*/}
                  <h3 className='text-white m-0 '><b>{Title}:</b> {renderPercentage(StatusData?.Percentage)}</h3>
                </td>
                <td className="tiny text-end pe-2">
                  {/*{StatusData ? */}
                  {/*  return (Updated at {new Date(StatusData?.DateModified).toProperDate()} <br />By {StatusData?.UserName}) : ""*/}
                  {/*}*/}
                  {StatusData?.Id > 0 ? (
                    <>
                      Updated at {new Date(StatusData?.DateModified).toProperDate()} <br />
                      By {StatusData?.UserName}
                    </>
                  ) : (
                    ""
                  )}
                </td>
                <td style={{ width: "180px" }}>
                  <button onClick={toggle} className='btn btn-info'><i className="far fa-edit me-2"></i>Update Status</button>
                </td>
                {renderHelp()}

              </tr>
            </tbody>
          </table>
        </div>


        <Modal isOpen={editModal} toggle={toggle} scrollable size="md" backdrop="static" >
          <ModalHeader toggle={toggle}>{Title}</ModalHeader>
          <ModalBody>
            <div className='row'>
              <div className='mb-3 col-md-4'>
                <NumericTextBoxComponent id='tbRiskAnalysis' name='tbRiskAnalysis' format="##0" min={0} max={100} step={1} placeholder='Percentage' floatLabelType='Always' showClearButton={true} value={percentage} onChange={e => setPercentage(e.target.value)} /> <div id='tbRiskAnalysisError' className='error-message' />
              </div>
              <div className='mb-3 col-12'>
                <TextBoxComponent multiline id='tbRiskAnalysisComment' name='tbRiskAnalysisComment' placeholder='Comment' type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={note} onChange={e => setNote(e.value)} /> <div id='tbRiskAnalysisCommentError' className='error-message' />
              </div>

              {StatusData?.RiskId ? <>
                <h5 className="col-12 border-bottom">Previous Engagement</h5>
                <div className='mb-3 col-md-4'>
                  <TextBoxComponent id='tbPreviousPercentage' name='tbPreviousPercentage' placeholder='Previous Percentage' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={StatusData?.Percentage} />
                </div>
                <div className='mb-3 col-md-4'>
                  <TextBoxComponent id='tbModifiedBy' name='tbModifiedBy' placeholder='Modified By' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={StatusData?.UserName} />
                </div>
                <div className='mb-3 col-md-4'>
                  <TextBoxComponent id='tbPreviousDate' name='tbPreviousDate' placeholder='Modified Date' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={new Date(StatusData?.DateModified).toProperDate()} />
                </div>
                <div className='mb-3 col-md-12'>
                  <TextBoxComponent multiline id='tbPreviousComment' name='tbPreviousComment' placeholder='Previous Comment' disabled type='text' maxLength='2500' floatLabelType='Always' showClearButton={true} value={StatusData?.Note} />
                </div>
              </> : ""}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal >


        <Modal isOpen={helpModal} toggle={toggleHelp} scrollable size="lg" backdrop="static" >
          <ModalHeader toggle={toggleHelp}>Section Help</ModalHeader>
          <ModalBody>
            <div dangerouslySetInnerHTML={{ __html: HintText.replace(/\n/g, '<br />') }} />
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={toggleHelp}>Close <i className="far fa-times-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal >
      </>
    );
  }
};

export default RiskStatusTitle;