/* eslint-disable no-extend-native */

Date.prototype.toProperDate = function (DefaultValue) {
  // Get the year, month, and day
  let defaultValue = DefaultValue ? DefaultValue : "Not Started";
  if (!(this instanceof Date)) {
    return defaultValue;
  }

  // Check if the date is valid
  if (isNaN(this.getTime())) {
    return defaultValue;
  }

  // Check if the value is not 1-0-1
  if (this.getFullYear() === 1 && this.getMonth() === 0 && this.getDate() === 1) {
    return defaultValue;
  }

  const year = this.getFullYear();
  const month = String(this.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(this.getDate()).padStart(2, '0');

  // Return the date in yyyy-MM-dd format
  return `${year}-${month}-${day}`;
};