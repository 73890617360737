import { useEffect } from 'react';
import { Table } from 'reactstrap';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';


const Controls = ({ ControlsData, SelectedItems, HandleHighlight }) => {

  useEffect(() => {

  }, []);


  const clickHandler = (ItemId) => {
    //console.log("Clicked!", ItemId);
    if (HandleHighlight)
      HandleHighlight("controls", ItemId);
  }

  const renderCheckbox = (IsChecked, OtherChecked) => {
    if (IsChecked)
      return (<div style={{ marginLeft: "auto", marginRight: "auto", width: "20px", height: "20px", border: "1px grey solid", display: "grid", placeContent: "center" }}><i className="fa-solid fa-check fs-6"></i></div>);
    else if (OtherChecked)
      return (<div style={{ marginLeft: "auto", marginRight: "auto", width: "20px", height: "20px", border: "1px grey solid", backgroundColor: "#e69c24" }}></div>)
    else
      return (<div style={{ marginLeft: "auto", marginRight: "auto", width: "20px", height: "20px", border: "1px grey solid" }}></div>);
  };

  return (
    <>
      <div className='card h-100'>
        <div className='card-header'>
          <h5 className="m-0">Controls</h5>
        </div>
        <div className='card-body risk-body'>

          <div className='text-center mb-2 text-muted tiny'>(Click a Control to see related root causes & consequences)</div>
          <div className="table-responsive">
            <Table size="sm" striped hover className="table-highlight">
              <thead>
                <tr>
                  <th></th>
                  <th className="fw-bold">Control #</th>
                  <th className="fw-bold">Short Name</th>
                  <th className="fw-bold"><i className="fa-regular fa-newspaper text-primary"></i></th>
                  <th className="fw-bold"><i className="fa-solid fa-paperclip text-primary"></i></th>
                  <th className="fw-bold">Critical?</th>
                  <th className="fw-bold">Needs Assurance?</th>
                </tr>
              </thead>
              <tbody>
                {ControlsData && ControlsData.map((item, index) => (
                  <tr key={index} onClick={() => clickHandler(item.Id)} className={SelectedItems?.includes(item.Id) ? 'selected' : ''}>
                    <td className="ps-2 pe-0">{!item.ConnectedConsequences.length && !item.ConnectedRootCauses.length ? (<i className="fa-solid fa-link-slash text-danger" title="This control has not yet been linked to any consequence or root cause"></i>) : null}</td>
                    <td>{item.ControlNumber}</td>
                    <td>
                      <TooltipComponent isSticky={false} content={item.Description} className="tooltip-normal" >
                        {item.ShortName}
                      </TooltipComponent>
                    </td>
                    <td>{item.FindingCount}</td>
                    <td>{item.AttachmentCount}</td>
                    <td>{renderCheckbox(item.Critical, item.NeedsAssurance)}</td>
                    <td>{renderCheckbox(item.NeedsAssurance, item.Critical)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>


        </div>
      </div>
    </>
  );
};

export default Controls;