import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';
import { getAuthToken, getUserDetails, setAuthToken } from '../../../helpers/authentication';
import { Context } from '../../../helpers/Context';
import ThrobbleHelper from '../../../helpers/ThrobbleHelper';

class HealthCaptureControl extends Component {
  static contextType = Context;
  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      CompanyId: 0,
      Title: '',
      GraphTitle: '',
      DateAdded: new Date(),
      AddedBy: 0,
      DateModified: new Date(),
      ModifiedBy: 0,
      Status: 0,
      DisplayOrder: 0,

    };
    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    document.title = "Data Health - Capture Controls";
    this.loadData();
  }

  editItem = async (data, title) => {

    const editData = data.map((item) => ({ ...item, DateAdded: new Date(item.DateAdded).toProperDate() }));
    this.setState({ GraphTitle: title, GraphData: editData, editModal: true });
  };

  getStatusCounts = (risks, statuses) => {
    const completeRisks = risks.filter(risk =>
      statuses.some(status => risk.Id === status.RiskId && status.Percentage === 100)
    );

    const notCompleteRisks = risks.filter(risk =>
      statuses.some(status => risk.Id === status.RiskId && status.Percentage !== 100)
    );

    const combinedRisks = [
      ...completeRisks.map(risk => ({ ...risk, Complete: 'Complete' })),
      ...notCompleteRisks.map(risk => ({ ...risk, Complete: 'Not yet complete' })),
    ];

    const completeStatusCount = completeRisks.length;
    const notCompleteStatusCount = notCompleteRisks.length;

    return {
      counts: [
        { name: 'Complete', value: completeStatusCount },
        { name: 'Not yet complete', value: notCompleteStatusCount },
      ],
      risks: combinedRisks,
    };
  }

  getStatusCounts2 = (risks, controls) => {
    let allHaveConsequence = 0;
    let seventyFiveToHundred = 0;
    let fiftyToSeventyFive = 0;
    let twentyFiveToFifty = 0;
    let zeroToTwentyFive = 0;
    let noConsequence = 0;

    const risksArray = [];

    risks.forEach((risk) => {
      const relatedControls = controls.filter((control) => control.RiskId === risk.Id);
      //console.log(relatedControls)
      const controlsWithConsequence = relatedControls.filter((control) => control.ConnectedConsequences.length || control.ConnectedRootCauses.length);
      const percentage = (controlsWithConsequence.length / relatedControls.length) * 100;
      const newRisk = { ...risk, ConsequenceAndRootCause: '' };

      if (percentage === 100) {
        allHaveConsequence++;
        newRisk.ConsequenceAndRootCause = 'All have consequence/root cause';
      } else if (percentage >= 75 && percentage < 100) {
        seventyFiveToHundred++;
        newRisk.ConsequenceAndRootCause = '75-100%';
      } else if (percentage >= 50 && percentage < 75) {
        fiftyToSeventyFive++;
        newRisk.ConsequenceAndRootCause = '50-75%';
      } else if (percentage >= 25 && percentage < 50) {
        twentyFiveToFifty++;
        newRisk.ConsequenceAndRootCause = '25-50%';
      } else if (percentage >= 0 && percentage < 25) {
        zeroToTwentyFive++;
        newRisk.ConsequenceAndRootCause = '0-25%';
      } else {
        noConsequence++;
        newRisk.ConsequenceAndRootCause = 'No consequence/root cause';
      }
      risksArray.push(newRisk);
    });

    return {
      counts: [
        { name: 'All have consequence/root cause', value: allHaveConsequence },
        { name: '75-100%', value: seventyFiveToHundred },
        { name: '50-75%', value: fiftyToSeventyFive },
        { name: '25-50%', value: twentyFiveToFifty },
        { name: '0-25%', value: zeroToTwentyFive },
        { name: 'No consequence/root cause', value: noConsequence },
      ],
      risks: risksArray,
    };
  }

  getAnalysisStatusCounts = (risks, controls) => {
    let greaterThan9 = 0;
    let sixToNine = 0;
    let threeToFive = 0;
    let oneToTwo = 0;
    let noControls = 0;

    const risksArray = [];

    risks.forEach((risk) => {
      const relatedControls = controls.filter((control) => control.RiskId === risk.Id);
      const newRisk = { ...risk, ControlsCaptured: '' };

      const count = relatedControls.length;
      if (count > 9) {
        greaterThan9++;
        newRisk.ControlsCaptured = '>9 controls';
      } else if (count >= 6 && count <= 9) {
        sixToNine++;
        newRisk.ControlsCaptured = '6-9 controls';
      } else if (count >= 3 && count <= 5) {
        threeToFive++;
        newRisk.ControlsCaptured = '3-5 controls';
      } else if (count >= 1 && count <= 2) {
        oneToTwo++;
        newRisk.ControlsCaptured = '1-2 controls';
      } else {
        noControls++;
        newRisk.ControlsCaptured = 'No controls';
      }
      risksArray.push(newRisk);
    });

    return {
      counts: [
        { name: '>9 controls', value: greaterThan9 },
        { name: '6-9 controls', value: sixToNine },
        { name: '3-5 controls', value: threeToFive },
        { name: '1-2 controls', value: oneToTwo },
        { name: 'No controls', value: noControls },
      ],
      risks: risksArray,
    };
  }

  getEvaluationStatusCounts = (risks, controls) => {
    let greaterThan9 = 0;
    let sixToNine = 0;
    let threeToFive = 0;
    let oneToTwo = 0;
    let noCritical = 0;

    const risksArray = [];

    risks.forEach((risk) => {
      const relatedControls = controls.filter((control) => control.RiskId === risk.Id);

      const criticalControls = relatedControls.filter((control) => control.Critical);
      const newRisk = { ...risk, CriticalControls: '' };

      const criticalCount = criticalControls.length;
      if (criticalCount > 9) {
        greaterThan9++;
        newRisk.CriticalControls = '>9 critical';
      } else if (criticalCount >= 6 && criticalCount <= 9) {
        sixToNine++;
        newRisk.CriticalControls = '6-9 critical';
      } else if (criticalCount >= 3 && criticalCount <= 5) {
        threeToFive++;
        newRisk.CriticalControls = '3-5 critical';
      } else if (criticalCount >= 1 && criticalCount <= 2) {
        oneToTwo++;
        newRisk.CriticalControls = '1-2 critical';
      } else {
        noCritical++;
        newRisk.CriticalControls = 'No critical controls';
      }
      risksArray.push(newRisk);
    });

    return {
      counts: [
        { name: '>9 critical', value: greaterThan9 },
        { name: '6-9 critical', value: sixToNine },
        { name: '3-5 critical', value: threeToFive },
        { name: '1-2 critical', value: oneToTwo },
        { name: 'No critical', value: noCritical },
      ],
      risks: risksArray,
    };
  }

  renderTable(data) {
    //console.log(data)
    return (
      <div className='mb-3' >
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <p className='fw-bolder mb-0 w-50'>Total No. of Risks: {data?.length}</p>

              <Button color='primary' size="sm" onClick={(e) => this.ExportReport(e, data)}><i className='far fa-file-excel me-2'></i> Export To Excel</Button>
            </div>
            <GridComponent dataSource={data} >
              <ColumnsDirective>
                <ColumnDirective headerText='Risk Responsible Team' field='ResponsibleTeamName'></ColumnDirective>
                <ColumnDirective headerText='Risk Responsible Person' field='ResponsiblePersonName'></ColumnDirective>
                <ColumnDirective headerText='Risk No.' width='70' template={(props) => <Link to={"/risk-edit/" + props.RiskNumber} >{props.RiskNumber}</Link>}></ColumnDirective>
                <ColumnDirective headerText='Risk Name' field='Name'></ColumnDirective>
                <ColumnDirective headerText='Logged By' field='AddedByName'></ColumnDirective>
                <ColumnDirective headerText='Date Logged' field='DateAdded'></ColumnDirective>
                {data.some(obj => obj.hasOwnProperty('Complete')) && <ColumnDirective headerText='Complete' field='Complete'></ColumnDirective>}
                {data.some(obj => obj.hasOwnProperty('ConsequenceAndRootCause')) && <ColumnDirective headerText='Consequence & Root Cause' field='ConsequenceAndRootCause'></ColumnDirective>}
                {data.some(obj => obj.hasOwnProperty('ControlsCaptured')) && <ColumnDirective headerText='Controls Captured' field='ControlsCaptured'></ColumnDirective>}
                {data.some(obj => obj.hasOwnProperty('CriticalControls')) && <ColumnDirective headerText='Critical Controls' field='CriticalControls'></ColumnDirective>}
              </ColumnsDirective>
            </GridComponent>
          </CardBody>
        </Card>
      </div>
    );
  }

  renderDataTable(data, data2, data3, data4, colors, colors2, pieColors) {
    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text x={x} y={y} fill="#000" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {(percent * 100).toFixed(0) > 0 ? `${(percent * 100).toFixed(0)}%` : ""}
        </text>
      );
    };
    return (
      <>
        <div className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3 chart-styles">
          <div className="text-center my-3 text-light" onClick={() => this.editItem(data.risks, "Are my controls all captured?")}>
            <strong>Are my controls all captured?</strong>
            <ResponsiveContainer width="100%" height={300}>
              < PieChart width={500} height={300} >
                <Pie data={data.counts} dataKey="value" cx="50%" cy="50%" outerRadius={100} fill="#8884d8" labelLine={false} label={renderCustomizedLabel}>
                  {data.counts.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={pieColors[index % pieColors.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart >
            </ResponsiveContainer>
          </div>

          <div className="text-center my-3 text-light" onClick={() => this.editItem(data2.risks, "Do my risks have controls with at least 1 consequence or root cause?")}>
            <strong>Do my risks have controls with at least 1 consequence or root cause?</strong>
            <ResponsiveContainer width="100%" height={300}>
              < PieChart width={500} height={300} >
                <Pie data={data2.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#82ca9d" label={renderCustomizedLabel} >
                  {data2.counts.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart >
            </ResponsiveContainer>
          </div>
        </div>

        <div className="d-flex flex-row flex-wrap justify-content-evenly card bg-dark mb-3 chart-styles">
          <div className="text-center my-3 text-light" onClick={() => this.editItem(data3.risks, "How many controls have been captured against my risks?")}>
            <strong>How many controls have been captured against my risks?</strong>
            <ResponsiveContainer width="100%" height={400}>
              < PieChart width={500} height={300} >
                <Pie data={data3.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#8884d8" label={renderCustomizedLabel} >
                  {data3.counts.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors2[index % colors2.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart >
            </ResponsiveContainer>
          </div>

          <div className="text-center my-3 text-light" onClick={() => this.editItem(data4.risks, "How many critical controls have been captured against my risks?")}>
            <strong>How many critical controls have been captured against my risks?</strong>
            <ResponsiveContainer width="100%" height={400}>
              < PieChart width={500} height={300} >
                <Pie data={data4.counts} dataKey="value" cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#82ca9d" label={renderCustomizedLabel} >
                  {data4.counts.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colors2[index % colors2.length]} />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart >
            </ResponsiveContainer>
          </div>
        </div>
      </>
    );
  }

  render() {
    let capturedControls;
    let controlsWithConsequenceOrRoot;
    let controlsCount;
    let criticalControls;

    const pieColors = ['#32cd32', '#ffc0cb'];
    const colors = ['#32cd32', '#ffe4e1', '#ffc0cb', '#F08080', '#FF0000', '#B22222'];
    const colors2 = ['#228B22', '#8fbc8f', '#32cd32', '#98FB98', '#ffc0cb'];

    if (!this.state.loading) {
      capturedControls = this.getStatusCounts(this.state.editData, this.state.ControlStatusData);
      controlsWithConsequenceOrRoot = this.getStatusCounts2(this.state.editData, this.state.ControlList);

      controlsCount = this.getAnalysisStatusCounts(this.state.editData, this.state.ControlList);
      criticalControls = this.getEvaluationStatusCounts(this.state.editData, this.state.ControlList);

    }
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderDataTable(capturedControls, controlsWithConsequenceOrRoot, controlsCount, criticalControls, colors, colors2, pieColors);
    //console.log(this.state, evaluationStatuses, barData)
    return (
      <>
        <div className="container-fluid">
          <Row>
            <Col xs={6}>
              <h2>Data Health<br /><small>- Capture Controls -</small></h2>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Link to="/" className="btn btn-outline-dark btn-sm ms-2 mx-1"><i className="far fa-circle-left me-2"></i>Go Back</Link>
              <Button color='primary' size='sm' onClick={(e) => this.handleExport(e, capturedControls.counts, controlsWithConsequenceOrRoot.counts, controlsCount.counts, criticalControls.counts)}><i className='far fa-file-pdf me-2'></i>Export to PDF</Button>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>
        <div>

        </div>
        {<Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="xl" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="close" onClick={this.toggle}><i className="fas fa-times"></i></button>}>{this.state.GraphTitle}</ModalHeader>
          <ModalBody>
            {this.state.editModal && this.renderTable(this.state.GraphData)}
          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Close <i className="far fa-times-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal >}
        <ThrobbleHelper />
      </>

    );
  }

  async loadData() {
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();

    try {
      const response = await fetch(`api/controls/getbycompanyid/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        let data = await response.json();
        data = data.map(item => ({ ...item, Assurances: JSON.parse(item.Assurances), ConnectedRootCauses: item.ConnectedRootCauses ? JSON.parse(item.ConnectedRootCauses) : [], ConnectedConsequences: item.ConnectedConsequences ? JSON.parse(item.ConnectedConsequences) : [] }))
        this.setState({ ControlList: data });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/controlstatus`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ ControlStatusData: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskevaluatestatus`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskEvaluateStatusData: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskanalysisstatus`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ RiskAnalysisStatusData: data });
      }
      else {
        console.error(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/");
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/riskevaluation/GetRiskEvaluations`, {
        method: "GET",
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        const data = await response.json();
        //console.log(data)
        this.setState({ EvaluationData: data });
      }
    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch(`api/risks/getallcompanyrisks/${userData.CompanyId}/${userData.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data, loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          handleNavigate("/login");
      }

    } catch (e) {
      console.error(e);
    }

    setAuthToken(getAuthToken(), new Date());
  }

  handleExport = async (e, capturedControls, controlsWithConsequenceOrRoot, controlsCount, criticalControls) => {
    e.stopPropagation();
    ThrobbleHelper.ToggleThrobble(true, "Downloading Charts");
    const { handleNavigate } = this.context;
    var bearer = 'Bearer ' + getAuthToken();
    const userData = getUserDetails();

    let postData = {
      PieData: JSON.stringify(capturedControls),
      Pie2Data: JSON.stringify(controlsWithConsequenceOrRoot),
      Pie3Data: JSON.stringify(controlsCount),
      Pie4Data: JSON.stringify(criticalControls),
      Pie5Data: JSON.stringify([]),
      BarData: JSON.stringify([]),
      Bar2Data: JSON.stringify([]),
    }

    try {
      const response = await fetch('api/risks/downloadhealthcapturerisk', {
        method: "POST",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(postData)
      });

      if (response.ok) {
        const data = await response.json();
        const FileData = data.Item1;
        const MimeType = data.Item2;
        const Filename = data.Item3;
        const Base64String = `data:${MimeType};base64,${FileData}`;

        const LinkBtn = document.createElement("a");
        LinkBtn.download = Filename;
        LinkBtn.href = Base64String;
        LinkBtn.click();
        ThrobbleHelper.ToggleThrobble(false);
        ToastUtility.show({
          title: 'Charts', content: 'The charts was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      } else {
        if (response.status === 401) {
          ThrobbleHelper.ToggleThrobble(false);
          handleNavigate("/login");
        } else {
          ThrobbleHelper.ToggleThrobble(false);
          ToastUtility.show({
            title: 'Charts', content: 'There was an error downloading the charts!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
          });
        }
      }
    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false);
      ToastUtility.show({
        title: 'Charts', content: 'There was an error downloading the charts!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }

    setAuthToken(getAuthToken(), new Date());
  }

  async ExportReport(evt, data) {
    //const { handleNavigate } = this.context;
    const finalData = data.map(item => ({
      ResponsibleTeamName: item.ResponsibleTeamName,
      ResponsiblePersonName: item.ResponsiblePersonName,
      RiskNumber: item.RiskNumber,
      RiskName: item.Name,
      DateLogged: item.DateAdded,
      LoggedBy: item.AddedByName,
      Complete: item.Complete,
      ConsequenceAndRootCause: item.ConsequenceAndRootCause,
      ControlsCaptured: item.ControlsCaptured,
      CriticalControls: item.CriticalControls,
    }));
    evt.stopPropagation();
    var bearer = 'Bearer ' + getAuthToken();
    ThrobbleHelper.ToggleThrobble(true, "Exporting Table");
    try {

      //console.log(data)
      const response = await fetch("api/risks/datahealthcapturecontrol", {
        method: "POST",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json"
        },
        body: JSON.stringify(finalData)
      });

      if (response.ok) {
        const data = await response.json();
        const FileData = data.Item1;
        const MimeType = data.Item2;
        const Filename = data.Item3;
        const Base64String = `data:${MimeType};base64,${FileData}`;

        const LinkBtn = document.createElement("a");
        LinkBtn.download = Filename;
        LinkBtn.href = Base64String;
        LinkBtn.click();
        ThrobbleHelper.ToggleThrobble(false);
        ToastUtility.show({
          title: 'Table', content: 'The table was successfully downloaded!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });

      } else {
        if (response.status === 401) {
          //handleNavigate("/login");
        } else {
          ThrobbleHelper.ToggleThrobble(false);
          ToastUtility.show({
            title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
          });
        };
      }
    } catch (e) {
      console.error(e);
      ThrobbleHelper.ToggleThrobble(false);
      ToastUtility.show({
        title: 'Table', content: 'There was an error downloading the table!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }

    setAuthToken(getAuthToken(), new Date());
  }
}

export default HealthCaptureControl;