import { Card, CardBody, CardHeader } from "reactstrap";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const RootCauses = ({ RootCauseData, ControlsData, SelectedItems, HandleHighlight }) => {

  const clickHandler = (ItemId) => {
    //console.log("Clicked!", ItemId);
    if (HandleHighlight)
      HandleHighlight("causes", ItemId);
  }
  //console.log(RootCauseData);
  return (
    
    <>
      
      <Card className='h-100'>
        <CardHeader>
          <h5 className="m-0">Root Causes</h5>
        </CardHeader>
        <CardBody>
          <div className='text-center mb-2 text-muted tiny'>When rating the Probability, consider ALL these root causes</div>
          <table className="table table-sm table-striped table-hover table-highlight">
            <tbody>
            {RootCauseData && RootCauseData.map((item, index) =>
            (
              <tr key={index} onClick={() => clickHandler(item.Id)} className={SelectedItems?.includes(item.Id) ? 'selected': ''}>
                <td className="pe-1" style={{width: "40px"}}>
                  {ControlsData?.find(x => x.ConnectedRootCauses.includes(item.Id)) ? (
                    <></>
                  ) : <i className="fa-solid fa-link-slash text-danger me-2" title="This cause has not yet been linked to any control"></i>}
                </td>
                <td>
                  <TooltipComponent isSticky={false} content={item.Description} className="tooltip-normal" >
                    {item.ShortName}
                  </TooltipComponent>
                </td>
              </tr>

            ))}
            </tbody>
          </table>
        </CardBody>
      </Card>
    </>
  );
};

export default RootCauses;