import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { createRef, useEffect, useState } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Tooltip } from 'reactstrap';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import { ConfirmDialogStyles, GetProgressStyle } from '../../../helpers/global';
import { fieldRequired } from '../../../helpers/validation';
import EngagementModal from '../EngagementModal';
import { LoadAPIData, DeleteAPIData, ClearCachedAPIData } from '../../../helpers/DataHelper';

const Consequences = ({ RiskId, RootCausesData, ConsequenceData, ControlsData, CurrentSite, EventHandler, ReloadHandler }) => {


  //const [consequenceId, setConsequenceId] = useState(0);
  //const [consequences, setConsequences] = useState();
  const [riskStatus, setRiskStatus] = useState();
  const [perspectivesList, setPerspectivesList] = useState();
  const [connectedPerspectives, setConnectedPerspectives] = useState([]);

  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(false);

  const [editId, setEditId] = useState(0);
  const [shortName, setShortName] = useState('');
  const [description, setDescription] = useState('');


  const toggle = () => setEditModal(!editModal);

  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (id) => {
    setTooltipOpen((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const statusRef = createRef();

  const validateForm = () => {
    var valid = true;
    valid &= fieldRequired(shortName, 'tbShortNameError', '* required');

    return valid;
  }


  const saveItem = async (event) => {
    event.stopPropagation();
    const User = getUserDetails();
    if (validateForm()) {
      const data = { id: editId, riskId: RiskId, shortName: shortName, description: description, companyId: User.CompanyId, connectedPerspectives: JSON.stringify(connectedPerspectives), };
      await ClearCachedAPIData(`api/consequences/getbyriskid/${RiskId}/${User.UserRole}`);
      //console.log(data);
      var bearer = 'Bearer ' + getAuthToken();

      try {
        const response = await fetch('/api/consequences/' + User.UserRole, {
          method: editId === 0 ? 'POST' : 'PUT',
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data),
        });
        if (response.ok) {
          await response.json();
          //loadData();
          if (ReloadHandler)
            ReloadHandler();
          ToastUtility.show({
            title: 'Consequences', content: 'The consequences was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
          });
        }
        else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401)
            window.location.href = "/login";
        }

      } catch (e) {
        console.error(e);
        ToastUtility.show({
          title: 'Consequences', content: 'There was an error saving the consequences!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
      toggle();
    }


  }

  const loadData = async () => {
    const User = getUserDetails();
    var bearer = 'Bearer ' + getAuthToken();
    //console.log(ConsequenceData);
    try {

      const response = await fetch(`api/evaluationperspectives/listallbycompany/${User.CompanyId}/${User.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        //todo: this needs to be filtered by the risk site
        setPerspectivesList(data);
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }

    } catch (e) {
      console.error(e);
    }
    loadRiskStatus();
  }

  const loadRiskStatus = async () => {
    const User = getUserDetails();
    setRiskStatus(await LoadAPIData(`api/consequencestatus/getbyriskid/${RiskId}/${User.UserRole}`));
    setLoading(false);
  }

  const editItem = (id) => {
    if (id > 0) {
      const data = ConsequenceData.find((item) => { return item.Id === id });
      setEditId(data.Id);
      setShortName(data.ShortName);
      setDescription(data.Description);
      setConnectedPerspectives(data.ConnectedPerspectives)
    }
    else {
      //clear state fields
      setEditId(0);
      setShortName('');
      setDescription('');
      setConnectedPerspectives([])
    }
    setEditModal(true);
  };

  const deleteItem = async (dataId) => {
    if (await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles)) {
      const userData = getUserDetails();
      const result = await DeleteAPIData('/api/consequences/' + userData.UserRole + "/" + dataId)
      if (result) {
        loadData();
        if (ReloadHandler)
          ReloadHandler();
        ToastUtility.show({
          title: 'Risk Consequences', content: 'The consequence was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        ToastUtility.show({
          title: 'Risk Consequences', content: 'There was an error deleting the consequence!', timeOut: 5000, position: { X: 'Right', Y: 'Bottom' }, showCloseButton: true, cssClass: 'toast-danger'
        });
      }
    }
  }

  const handlePerspectiveChange = (id) => {
    const updatedConnectedPerspectives = connectedPerspectives?.includes(id)
      ? connectedPerspectives.filter((perspectiveId) => perspectiveId !== id)
      : [...connectedPerspectives, id];

    setConnectedPerspectives(updatedConnectedPerspectives);
  };

  useEffect(() => {
    loadData();
  }, []);


  if (!loading)
    return (
      <>

        <div className='text-center' style={GetProgressStyle(riskStatus?.Percentage)}><small>{riskStatus?.Percentage > 0 ? `${riskStatus?.Percentage}% - ${new Date(riskStatus?.DateModified).toProperDate() }` : "Not Yet Started"}</small></div>
        <div className='card h-100 flat-top'>
          <div className='card-header'>
            
            <table className="w-100 ">
              <tbody>
                <tr>
                  <td>Consequences</td>
                  <td className="text-end"><i className="fa-regular fa-square-plus" onClick={() => editItem(0)} title='Add Consequence'></i></td>
                </tr>
              </tbody>
            </table>
            <small>(Click the <strong>"+"</strong> icon to add a Consequence)</small>
          </div>
          <div className='card-body risk-body'>
            
            <table className="w-100 ">
              <tbody>
                {ConsequenceData && ConsequenceData.map((item, index) => {
                  const tooltipId = `tooltip-consequence-${index}`;

                  return (
                    <tr key={index}>
                      <td>
                        {ControlsData?.find(x => x.ConnectedConsequences.includes(item.Id)) ? (
                          <></>
                        ) : <i className="fa-solid fa-link-slash text-danger me-2" title="This consequence has not yet been linked to any control"></i>}

                      </td>
                      <td>
                        <span id={tooltipId}>
                          {item.ShortName}
                        </span>
                        <Tooltip placement="top" isOpen={tooltipOpen[tooltipId] || false} toggle={() => toggleTooltip(tooltipId)} target={tooltipId}>
                          {
                            item.Description
                          }
                        </Tooltip>
                      </td>

                      <td className="text-end">
                        <i onClick={() => editItem(item.Id)} className="e-edit e-icons me-2" title='Edit Consequence'></i>
                        <i onClick={() => deleteItem(item.Id)} className="far fa-trash-can text-danger" title='Remove Consequence'></i>
                      </td>
                    </tr>
                  )
                })}

              </tbody>
            </table>


          </div>
          <div className="card-footer text-center">
            {ConsequenceData?.length > 0 ? <button onClick={() => statusRef?.current.showModal()} className='btn btn-info btn-sm'><i className="far fa-edit me-2"></i>Update Status</button> : ""}
          </div>
        </div>


        <Modal isOpen={editModal} toggle={toggle} scrollable size="md" backdrop="static">
          <ModalHeader toggle={toggle}>Consequences</ModalHeader>
          <ModalBody>
            <div className='mb-2'>
              <TextBoxComponent id='tbShortName' name='tbShortName' placeholder='Short Name' type='text' maxLength='250' floatLabelType='Always' showClearButton={true} value={shortName} onChange={e => setShortName(e.value)} />
              <div id='tbShortNameError' className='error-message' />
            </div>

            <div className='mb-4'>
              <TextBoxComponent multiline id='tbDescription' name='tbDescription' placeholder='Description' type='text' maxLength='500' floatLabelType='Always' showClearButton={true} value={description} onChange={e => setDescription(e.value)} />
              <div id='tbDescriptionError' className='error-message' />
            </div>
            <h6 className="border-bottom">Link to Evaluation Perspectives</h6>
            <div className='row'>
              {perspectivesList?.length ? perspectivesList.map((perspective, index) => (
                <div className='mb-2 col-md-6' key={index}>
                  <CheckBoxComponent
                    id={`cbConsequence_${perspective.Id}`}
                    name={`cbConsequence_${perspective.Id}`}
                    type="checkbox"
                    label={perspective.Title}
                    checked={connectedPerspectives?.includes(perspective.Id)}
                    change={() => handlePerspectiveChange(perspective.Id)}
                  />
                </div>
              )) : <span className='text-center'>Create perspectives first to link to a Consequence</span>}
            </div>


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <EngagementModal key="mdlStatus" ref={statusRef} Title="Consequences Status" EngagementSaved={loadRiskStatus} Data={{ Id: RiskId, PreviousPercentage: riskStatus.Percentage, UserName: riskStatus.UserName, PreviousDate: riskStatus.DateModified, PreviousComment: riskStatus.Note, Route: 'api/consequencestatus/' + getUserDetails().UserRole }} />
      </>
    );
};

export default Consequences;