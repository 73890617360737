import { useEffect, useState } from 'react';
import { getAuthToken, getUserDetails } from '../../../helpers/authentication';
import RiskStatusTitle from '../Common/RiskStatusTitle';
import Evolution from './Evolution';
import RiskTypeDetail from './RiskTypeDetail';


const EvaluateRisk = ({ RiskId, RiskData, CompanyData, SiteData, RiskEvaluations, EventHandler, IsVisible, OnStatusUpdate }) => {
  const [editData, setEditData] = useState();
  const [colClass, setColClass] = useState("col-md-4");
  const [potentialClass, setPotentialClass] = useState("col-md-4");

  const loadData = async () => {
    const User = getUserDetails();
    var bearer = 'Bearer ' + getAuthToken();

    try {
      const response = await fetch(`api/riskevaluatestatus/getbyriskid/${RiskId}/${User.UserRole}`, {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        setEditData(data);
        if (OnStatusUpdate)
          OnStatusUpdate();
      }
      else {
        console.error(response.status + ": " + response.statusText);
      }
    } catch (e) {
      console.error(e);
    }
  }


  useEffect(() => {
    loadData();
    if (CompanyData.PotentialRiskUsed !== 1) {
      setColClass("col-md-6");
      setPotentialClass("d-none");
    }

  }, []);

  if (IsVisible)
    return (
      <>
        <RiskStatusTitle RiskId={RiskId} Title='Evaluate the Risk' StatusPath='api/riskevaluatestatus' UpdateHandler={loadData} StatusData={editData} HintText={`What is this screen about?\n\n"Evaluate the Risk" is all about MEASUREMENT of this risk.\nIt involves doing three things...\n1. Determine your 'potential' risk level - the risk you face without the controls that you may have in place.\nDoing this is referred to as performing a 'Potential Risk Rating'.\n2. Determine your 'actual' risk level - the risk you actually face taking into account the controls that you already have in place.  \nDoing this is referred to as performing a 'Actual Risk Rating'.\n3. Determine your 'targeted' risk level - the risk level that you consider acceptable, together with a date that you wish to see this risk level attained.\nDoing this is referred to as performing a 'Target Risk Rating'.\n\nWhen all these three have been completed, then you have completed this phase.\n\nHow do I engage with this screen?\nClick on the respective 'Edit' buttons takes you to the screen which allows you to perform the respective risk rating. \n\nPlease keep completion status up to date...\nThe phase completion status is shown above the "Evaluate the Risk" button.\nPlease update this status periodically, in the bar at the bottom of this screen.  \n\nIn that way any user immediately sees the correct completion status at a glance.`} />
        <div className="row evaluation-container">
          <div className={potentialClass}>
            <RiskTypeDetail BlockHeading="POTENTIAL" TypeData={RiskEvaluations.PotentialData} Site={SiteData} RiskImpactRatings={RiskEvaluations.RiskImpactRatings} IsVisible={IsVisible} EventHandler={EventHandler } />
          </div>
          <div className={colClass}>
            <RiskTypeDetail BlockHeading="ACTUAL" TypeData={RiskEvaluations.ActualData} Site={SiteData} RiskImpactRatings={RiskEvaluations.RiskImpactRatings} IsVisible={IsVisible} EventHandler={EventHandler} />
          </div>
          <div className={colClass}>
            <RiskTypeDetail BlockHeading="TARGET" TypeData={RiskEvaluations.TargetData} Site={SiteData} RiskImpactRatings={RiskEvaluations.RiskImpactRatings} IsVisible={IsVisible} EventHandler={EventHandler} />
          </div>
          <div className="col-12">
            <Evolution PotentialData={RiskEvaluations.PotentialData} ActualData={RiskEvaluations.ActualData} TargetData={RiskEvaluations.TargetData} IsVisible={IsVisible} />
          </div>
        </div>
        
      </>
    );
};

export default EvaluateRisk;