import { useEffect } from 'react';
import { GetProgressStyle } from '../../../helpers/global';

const RiskStatus = ({ RiskData, RiskAnalysisStatusData, RiskEvaluateStatusData, RiskTreatStatusData, RiskPlanStatusData, EventHandler }) => {

  useEffect(() => {

  }, []);

  return (
    <>
      <div className="risk-heading">
        <div className='row py-3 '>
          <div className='pb-3 col-md-9 text-center'>
            <strong>Risk Management</strong>
            {/*<div className='d-flex justify-content-center flex-wrap'>*/}
            <div className='row justify-content-center'>
              <div className='col-md-4'>
                {/*<small className="p-1" style={{ backgroundColor: RiskAnalysisStatusData?.Percentage > 0 && RiskAnalysisStatusData?.Percentage < 100 ? 'orange' : RiskAnalysisStatusData?.Percentage === 100 ? 'lime' : 'transparrent' }}>{RiskAnalysisStatusData?.Percentage > 0 ? `${RiskAnalysisStatusData?.Percentage}% - ${new Date(RiskAnalysisStatusData?.DateModified).toProperDate()}` : "Not Yet Started"}</small> <br />*/}
                <small className="p-1" style={GetProgressStyle(RiskAnalysisStatusData?.Percentage)}>{RiskAnalysisStatusData?.Percentage > 0 ? `${RiskAnalysisStatusData?.Percentage}% - ${new Date(RiskAnalysisStatusData?.DateModified).toProperDate()}` : "Not Yet Started"}</small> <br />
                <button onClick={() => EventHandler("analyse")} className='btn risk-btn-main mt-1'>Analyse</button>
              </div>
              <div className='col-md-4'>
                <small className="p-1" style={GetProgressStyle(RiskEvaluateStatusData?.Percentage)}>{RiskEvaluateStatusData?.Percentage > 0 ? `${RiskEvaluateStatusData?.Percentage}% - ${new Date(RiskEvaluateStatusData?.DateModified).toProperDate()}` : "Not Yet Started"}</small> <br />
                <button onClick={() => EventHandler("evaluate")} className='btn risk-btn-main mt-1'>Evaluate</button>
              </div>
              <div className='col-md-4'>
                <small className="p-1" style={GetProgressStyle(RiskTreatStatusData?.Percentage)}>{RiskTreatStatusData?.Percentage > 0 ? `${RiskTreatStatusData?.Percentage}% - ${new Date(RiskTreatStatusData?.DateModified).toProperDate()}` : "Not Yet Started"}</small> <br />
                <button onClick={() => EventHandler("treat")} className='btn risk-btn-main mt-1'>Treat</button>
              </div>
            </div>
          </div>

          <div className='mb-3 col-md vertical text-center'>
            <strong>Assurances Management</strong>
            <div className=''>
              <small className="p-1" style={GetProgressStyle(RiskPlanStatusData?.Percentage)}>{RiskPlanStatusData?.Percentage > 0 ? `${RiskPlanStatusData?.Percentage}% - ${new Date(RiskPlanStatusData?.DateModified).toProperDate()}` : "Not Yet Started"}</small> <br />
              <button onClick={() => EventHandler("plan")} className='btn risk-btn-main mt-1'>Plan</button>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default RiskStatus;