import { getAuthToken } from './authentication';

const replaceAll = (str, find, replace) => {
  if (!str || !find) return str;
  const regex = new RegExp(find, 'g'); // 'g' flag ensures global replacement
  return str.replace(regex, replace);
};

export const LoadAPIData = async (ApiUrl) => {
  var bearer = 'Bearer ' + getAuthToken();
  try {
    const response = await fetch(ApiUrl, {
      method: 'GET',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json'
      }
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    }
    else {
      console.log(response.status + ": " + response.statusText);
      if (response.status === 401)
        window.location.href = "/login";
      return null;
    }

  } catch (e) {
    console.error(e);
    return null;
  }
}

export const LoadCachedAPIData = async (ApiUrl) => {
  const key = replaceAll(ApiUrl, "/", "_");
  const value = await sessionStorage.getItem(key);
  if (value) {
    return JSON.parse(value);
  } else {
    const data = await LoadAPIData(ApiUrl);
    sessionStorage.setItem(key, JSON.stringify(data));
    return data;
  }
}

export const ClearCachedAPIData = async (ApiUrl) => {
  try {
    const key = replaceAll(ApiUrl, "/", "_");
    sessionStorage.removeItem(key);
  } catch (e) {

  }
}


export const SaveAPIData = async (ApiUrl, Data, Method) => {

  ClearCachedAPIData(ApiUrl);

  var bearer = 'Bearer ' + getAuthToken();

  try {
    const response = await fetch(ApiUrl, {
      method: Method,
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(Data),
    });
    if (response.ok) {
      await response.json();
      return true;
    }
    else {
      console.log(response.status + ": " + response.statusText);
      if (response.status === 401)
        window.location.href = "/login";
      return false;
    }

  } catch (e) {
    console.error(e);
    return false;
  }
}

export const DeleteAPIData = async (ApiUrl) => {
  var bearer = 'Bearer ' + getAuthToken();
  try {
    const response = await fetch(ApiUrl, {
      method: 'DELETE',
      withCredentials: true,
      credentials: 'include',
      headers: {
        'Authorization': bearer,
        'Content-Type': 'application/json'
      }
    });
    if (response.ok) {
      await response.json();
      return true;
    }
    else {
      console.log(response.status + ": " + response.statusText);
      if (response.status === 401)
        window.location.href = "/login";
    }
  } catch (e) {
    console.error(e);
    return false;
  }
}